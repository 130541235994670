import {Link} from 'gatsby';
import React from 'react';

import {getFlagEmoji} from '@travelfreedom/shared';

import {Knowledge} from '../library/common';

type TableListEntryTargetType = 'passport' | 'destination';

export interface TableListEntryProps {
  code: string;
  condition: string;
  targetType: TableListEntryTargetType;
}

export const TableListEntry = ({code, condition, targetType}: TableListEntryProps): JSX.Element => (
  <tr>
    <td className="py-2 px-4" style={{width: '20rem'}}>
      <Link to={`/${targetType}/${Knowledge.isoCodeToPath(code)}`}>
        {getFlagEmoji(code)} {Knowledge.isoCodeToName(code)}
      </Link>
    </td>
    <td>{condition}</td>
  </tr>
);
