import {Dialog, Transition} from '@headlessui/react';
import {GatsbyImage, getImage, IGatsbyImageData} from 'gatsby-plugin-image';
import React, {CSSProperties, Fragment, useRef, useState} from 'react';

import {Passport} from '../library/common';

export interface PassportImageData {
  smallImage: IGatsbyImageData;
  largeImage: IGatsbyImageData;
}

type PassportImageFormat = 'HORIZONTAL' | 'VERTICAL';

export interface PassportImageProps extends PassportImageData {
  passport: Passport.Data;
  format: PassportImageFormat;
}

const GATSBY_IMAGE_STYLES: Record<PassportImageFormat, CSSProperties> = {
  VERTICAL: {minWidth: '110px'},
  HORIZONTAL: {minWidth: '150px', height: '100px'},
};

export const PassportImage = ({
  smallImage,
  largeImage,
  passport,
  format,
}: PassportImageProps): JSX.Element => {
  const [isLargeImageOpen, setLargeImageOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const imageAlt = `${Passport.adjectiveName(passport)} image`;

  return (
    <>
      <GatsbyImage
        alt={imageAlt}
        image={getImage(smallImage)}
        className="mr-3"
        onClick={() => setLargeImageOpen(true)}
        style={GATSBY_IMAGE_STYLES[format]}
      />
      <Transition.Root show={isLargeImageOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setLargeImageOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className="inline-block align-bottom bg-white rounded-lg 
                text-left overflow-hidden shadow-xl transform transition-all 
                sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
              >
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="
                      mt-3 w-full inline-flex justify-center rounded-md px-4 py-2 
                      border border-gray-300 shadow-sm 
                    bg-white text-base font-medium text-gray-700 
                     hover:bg-gray-50 focus:outline-none 
                     sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setLargeImageOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <GatsbyImage alt={imageAlt} image={getImage(largeImage)} className="mr-3" />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
