import React, {useState} from 'react';

import type {ReactNode} from 'react';
import {getFlagEmoji, PassportType} from '@travelfreedom/shared';
import type {Passport} from '../library/common';

const UpDownButton = (): JSX.Element => {
  return (
    <svg
      className="h-5 w-5 text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
};

// We need this because TSX/JSX doesn't support generic types
type SelectValueT = string | Passport.Data;

type RequiredProps<T> = {
  values: T[];
  defaultText: string;
};

type DefaultProps<T> = {
  initialSelectedValue?: T;
  onSelect?: (value: T) => void;
  renderValueFn?: (model: T) => ReactNode;
  keyFn?: (model: T) => ReactNode;
};

const defaultProps: DefaultProps<SelectValueT> = {
  initialSelectedValue: null,
  onSelect: () => undefined,
  renderValueFn: (model) => model,
  keyFn: (model) => model,
};

export type SelectProps<T> = RequiredProps<T> & DefaultProps<T>;

export const Select = ({
  values,
  defaultText,
  initialSelectedValue,
  onSelect,
  renderValueFn,
  keyFn,
}: SelectProps<SelectValueT>): JSX.Element => {
  const [selectboxOpen, setSelectboxOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<SelectValueT>(initialSelectedValue);
  return (
    <div className="mt-1 relative">
      <button
        type="button"
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-gray-500 sm:text-sm"
        onClick={() => setSelectboxOpen(true)}
        onBlur={() => setSelectboxOpen(false)}
      >
        <span className="flex items-center">
          {selectedValue ? renderValueFn(selectedValue) : defaultText}
        </span>
        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <UpDownButton />
        </span>
      </button>

      <div
        className={`${
          !selectboxOpen && 'hidden'
        } absolute mt-1 w-full rounded-md bg-white shadow-lg`}
      >
        <ul
          tabIndex={-1}
          role="listbox"
          aria-labelledby="listbox-label"
          aria-activedescendant="listbox-item-3"
          className="max-h-96 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
        >
          {values.map((value: SelectValueT) => (
            <li
              id={`listbox-item-${keyFn(value)}`}
              key={`listbox-item-${keyFn(value)}`}
              role="option"
              aria-selected="false"
              className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9"
              onMouseDown={() => {
                setSelectedValue(value);
                onSelect(value);
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  setSelectedValue(value);
                  onSelect(value);
                }
              }}
            >
              <div className="flex items-center">{renderValueFn(value)}</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
Select.defaultProps = defaultProps;

type SelectCountryRequiredProps = {
  passports: Passport.Data[];
};

type SelectCountryDefaultProps = {
  selectedPassport?: Passport.Data;
  onSelect?: (passport: Passport.Data) => void;
};

export type SelectCountryProps = SelectCountryRequiredProps & SelectCountryDefaultProps;

export const SelectCountry = ({
  passports,
  selectedPassport = null,
  onSelect = () => undefined,
}: SelectCountryProps): JSX.Element => {
  return (
    <Select
      values={passports}
      initialSelectedValue={selectedPassport}
      onSelect={onSelect}
      defaultText="Choose Your Passport"
      keyFn={(passport: Passport.Data) => passport.globalCode}
      renderValueFn={(passport: Passport.Data) => (
        <>
          {getFlagEmoji(passport.code)}
          <span className="ml-3 block font-normal truncate">{passport.name}</span>
        </>
      )}
    />
  );
};
SelectCountry.defaultProps = {
  selectedPassport: null,
  onSelect: () => undefined,
};

type SelectPassportTypeRequiredProps = unknown;

type SelectPassportTypeDefaultProps = {
  selectedType?: PassportType;
  onSelect?: (passport: PassportType) => void;
};

export type SelectPassportTypeProps = SelectPassportTypeRequiredProps &
  SelectPassportTypeDefaultProps;

export const SelectPassportType = ({
  selectedType = null,
  onSelect = () => undefined,
}: SelectPassportTypeProps): JSX.Element => {
  return (
    <Select
      values={['ORDINARY', 'OFFICIAL', 'DIPLOMATIC']}
      initialSelectedValue={selectedType}
      onSelect={onSelect}
      defaultText="Choose passport type"
      keyFn={(type) => type}
      renderValueFn={(type: PassportType) => (
        <span className="ml-3 block font-normal truncate">
          {
            {
              ORDINARY: 'Ordinary passport',
              DIPLOMATIC: 'Diplomatic passport',
              OFFICIAL: 'Official passport',
            }[type]
          }
        </span>
      )}
    />
  );
};
SelectPassportType.defaultProps = {
  selectedType: null,
  onSelect: () => undefined,
};
