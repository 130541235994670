import {
  IndividualRanking,
  MongoPassport,
  PassportType,
  RankingCriteria,
  WebPassport,
  fromMapArray,
  underscore,
} from '@travelfreedom/shared';

import {VisaLists} from './visalists';

/**
 * Represents a travel document.
 *
 * It doesn't necessarily have to be a passport - can also be a national ID.
 *
 * We don't call it `Document`, so it doesn't clash names with HTML Document.
 */
export namespace Passport {
  export interface Data extends VisaLists.Data {
    type: PassportType;
    adjective: string;
    ranking?: IndividualRanking;
  }

  export function from(code: string, type: PassportType, name: string, adjective: string): Data {
    return {
      code,
      name,
      adjective,
      type,
      globalCode: Passport.getGlobalCode(code, type),
      loaded: false,
      visafreeList: [],
      etaList: [],
      visapriorList: [],
      bannedList: [],
    };
  }

  export function fromJson(json: MongoPassport): Data {
    const {globalCode, name, adjective, visaList, ranking} = json;
    const code = Passport.getCode(globalCode);
    const type = Passport.getType(globalCode);
    const conditions = fromMapArray(json.conditions);
    const data: Data = {
      code,
      globalCode,
      name,
      adjective,
      type,
      conditions,
      ranking,
      loaded: true,
    };
    VisaLists.loadVisaLists(data, visaList);
    return data;
  }

  // Lean JSON contains only minimum data and ranking
  export function fromLeanJson(json: MongoPassport): Data {
    const {globalCode, name, adjective, ranking} = json;
    const code = Passport.getCode(globalCode);
    const type = Passport.getType(globalCode);
    return {
      code,
      globalCode,
      name,
      adjective,
      type,
      ranking,
      loaded: false,
      visafreeList: [],
      etaList: [],
      visapriorList: [],
      bannedList: [],
    };
  }

  export function loadJson(data: Data, json: WebPassport): Passport.Data {
    const {conditions, visaList, ranking} = json;
    VisaLists.loadVisaLists(data, visaList);
    // eslint-disable-next-line no-param-reassign
    data.conditions = conditions;
    // eslint-disable-next-line no-param-reassign
    data.ranking = ranking;
    // eslint-disable-next-line no-param-reassign
    data.loaded = true;
    return data;
  }

  export function getGlobalCode(code: string, type: PassportType): string {
    const typeGlobalCodes: Record<PassportType, string> = {
      ORDINARY: 'ORDI',
      OFFICIAL: 'OFFI',
      DIPLOMATIC: 'DIPL',
      NATIONAL_ID: 'NATI',
    };
    return `${typeGlobalCodes[type] || 'ORDI'}_${code}`;
  }

  export function getCode(globalCode: string): string {
    return globalCode.substr(5, 2);
  }

  export function getRankingByCriteria(data: Data, criteria: RankingCriteria) {
    const rankingMap: {[key in RankingCriteria]: number} = {
      COUNTRIES: data.ranking.rank,
      AREA: data.ranking.rankArea,
      POPULATION: data.ranking.rankPopulation,
    };
    return rankingMap[criteria];
  }

  export function getTravelfreedomByCriteria(data: Data, criteria: RankingCriteria) {
    const rankingMap: {[key in RankingCriteria]: number} = {
      COUNTRIES: data.ranking.travelfreedom,
      AREA: data.ranking.travelfreedomArea,
      POPULATION: data.ranking.travelfreedomPopulation,
    };
    return rankingMap[criteria];
  }

  export function getType(globalCode: string): PassportType {
    const globalCodeTypes: Record<string, PassportType> = {
      DIPL: 'DIPLOMATIC',
      OFFI: 'OFFICIAL',
      NATI: 'NATIONAL_ID',
      ORDI: 'ORDINARY',
    };
    return globalCodeTypes[globalCode.substr(0, 4)] || 'ORDINARY';
  }

  export function getTypePath(passportType: PassportType): string {
    const typeWebPaths: Record<PassportType, string> = {
      ORDINARY: 'passport',
      OFFICIAL: 'official_passport',
      DIPLOMATIC: 'diplomatic_passport',
      NATIONAL_ID: 'national_id',
    };
    return typeWebPaths[passportType];
  }

  function adjectiveSuffix(data: Data): string {
    const typeAdjectiveSuffixes: Record<PassportType, string> = {
      ORDINARY: 'passport',
      DIPLOMATIC: 'diplomatic passport',
      OFFICIAL: 'official passport',
      NATIONAL_ID: 'national ID',
    };
    return typeAdjectiveSuffixes[data.type] || 'passport';
  }

  export function hasExtraData(data: Data): boolean {
    return (
      VisaLists.hasVisaLists(data) &&
      VisaLists.hasConditions(data) &&
      (!!data.ranking || data.type === 'NATIONAL_ID')
    );
  }

  export function adjectiveName(data: Data): string {
    return `${data.adjective} ${adjectiveSuffix(data)}`;
  }

  export function underscoreName(data: Data): string {
    return underscore(data.name);
  }

  export function underscorePassportType(data: Data): string {
    return getTypePath(data.type);
  }

  export function hrefPath(data: Data): string {
    return `/${underscorePassportType(data)}/${underscoreName(data)}`;
  }

  export function fullHrefPath(data: Data): string {
    return `https://www.travelfreedom.io/${underscorePassportType(data)}/${underscoreName(data)}`;
  }
}
