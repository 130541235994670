import {PolicyCode} from '@travelfreedom/shared';

export namespace VisaLists {
  export interface Data {
    code: string;
    globalCode: string;
    name: string;

    visafreeList?: string[];
    etaList?: string[];
    visapriorList?: string[];
    bannedList?: string[];
    conditions?: Conditions;

    loaded: boolean;
  }

  export type FreedomCode = 'SELF' | PolicyCode;

  export interface Conditions {
    [key: string]: string;
  }

  export interface VisaList {
    visafree: string;
    eta: string;
    visaprior: string;
    banned: string;
  }

  export function travelfreedom(data: Data): number {
    return data.visafreeList.length + data.etaList.length;
  }

  export function allCodes(data: Data): string[] {
    return [
      data.code,
      ...data.visafreeList,
      ...data.etaList,
      ...data.visapriorList,
      ...data.bannedList,
    ];
  }

  export function hasVisaLists(data: Data): boolean {
    return (
      data.visafreeList.length > 0 ||
      data.etaList.length > 0 ||
      data.visapriorList.length > 0 ||
      data.bannedList.length > 0
    );
  }

  export function hasConditions(data: Data): boolean {
    return !!data.conditions;
  }

  function safeStringToList(concatenatedString: string): string[] {
    return !concatenatedString ? [] : concatenatedString.split(' ');
  }

  export function loadVisaLists(data: Data, visaLists: VisaList): void {
    // eslint-disable-next-line no-param-reassign
    data.visafreeList = safeStringToList(visaLists.visafree);
    // eslint-disable-next-line no-param-reassign
    data.etaList = safeStringToList(visaLists.eta);
    // eslint-disable-next-line no-param-reassign
    data.visapriorList = safeStringToList(visaLists.visaprior);
    // eslint-disable-next-line no-param-reassign
    data.bannedList = safeStringToList(visaLists.banned);
  }

  export function isVisaFree(data: Data, code: string): boolean {
    return data.visafreeList.includes(code.toUpperCase());
  }

  export function isEta(data: Data, code: string): boolean {
    return data.etaList.includes(code.toUpperCase());
  }

  export function isVisaPrior(data: Data, code: string): boolean {
    return data.visapriorList.includes(code.toUpperCase());
  }

  export function isBanned(data: Data, code: string): boolean {
    return data.bannedList.includes(code.toUpperCase());
  }

  export function getFreedomCode(data: Data, code: string): FreedomCode {
    if (data.code === code) return 'SELF';
    if (isVisaFree(data, code)) return 'VISAFREE';
    if (isEta(data, code)) return 'ETA';
    if (isVisaPrior(data, code)) return 'VISAPRIOR';
    if (isBanned(data, code)) return 'BANNED';
    throw Error(`${data.code} does not have travel freedom for ${code}`);
  }
}
