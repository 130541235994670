import {MongoDestination, fromMapArray, underscore} from '@travelfreedom/shared';
import {VisaLists} from './visalists';

export namespace Destination {
  export interface Data extends VisaLists.Data {
    explanation?: string;
  }

  export function getGlobalCode(code: string): string {
    return `DEST_${code}`;
  }

  export function getCode(globalCode: string): string {
    return globalCode.substr(5, 2);
  }

  export function from(code: string, name: string): Data {
    const globalCode = Destination.getGlobalCode(code);
    return {
      code,
      name,
      globalCode,
      loaded: false,
      visafreeList: [],
      etaList: [],
      visapriorList: [],
      bannedList: [],
    };
  }

  export function fromJson(json: MongoDestination): Data {
    const {globalCode, name, explanation, visaList} = json;
    const code = Destination.getCode(globalCode);
    const conditions = fromMapArray(json.conditions);
    const data: Data = {
      code,
      globalCode,
      name,
      explanation,
      conditions,
      loaded: true,
    };
    VisaLists.loadVisaLists(data, visaList);
    return data;
  }

  // Lean JSON contains only minimum data: global code and name.
  export function fromLeanJson(json: MongoDestination): Data {
    const {globalCode, name} = json;
    const code = Destination.getCode(globalCode);
    return {
      code,
      globalCode,
      name,
      loaded: false,
      visafreeList: [],
      etaList: [],
      visapriorList: [],
      bannedList: [],
    };
  }

  export function underscoreName(data: Data): string {
    return underscore(data.name);
  }

  export function hrefPath(data: Data): string {
    return `/destination/${underscoreName(data)}`;
  }

  export function fullHrefPath(data: Data): string {
    return `https://www.travelfreedom.io/destination/${underscoreName(data)}`;
  }
}
