import React, {ReactElement} from 'react';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    adsbygoogle: any;
  }
}

export default class GoogleAd extends React.Component {
  componentDidMount(): void {
    const installGoogleAds = (): void => {
      const elem = document.createElement('script');
      elem.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      elem.async = true;
      elem.defer = true;
      document.body.insertBefore(elem, document.body.firstChild);
    };
    installGoogleAds();
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render(): ReactElement {
    const divStyle: React.CSSProperties = {display: 'block'};
    return (
      <ins
        className="adsbygoogle"
        style={divStyle}
        data-ad-client="ca-pub-8631467550617966"
        data-ad-slot="2072796086"
        data-ad-format="auto"
      />
    );
  }
}
