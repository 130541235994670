import {Link} from 'gatsby';
import React from 'react';

import {getFlagEmoji} from '@travelfreedom/shared';

export interface CountryPillProps {
  code: string;
  name: string;
  href: string;
}

export const CountryPill = ({code, name, href}: CountryPillProps): JSX.Element => {
  return (
    <Link
      to={href}
      className="inline-flex flex-wrap space-x-1 m-1 p-2 border border-gray-200 rounded items-center"
    >
      <div>
        {getFlagEmoji(code)} {name}
      </div>
    </Link>
  );
};
