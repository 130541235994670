import {Link} from 'gatsby';
import type {PropsWithChildren} from 'react';
import React from 'react';

import Header from './header';

const Footer = (): JSX.Element => {
  return (
    <footer className="max-w-7xl mx-auto sm:px-6 border-t-2 border-gray-200 text-sm">
      <div className="grid md:grid-cols-2 md:gap-8">
        <div className="px-4">
          <p className="mt-2 text-gray-600">
            © {new Date().getFullYear()} TravelFreedom.io. All rights reserved.
          </p>
          <p className="mt-2 text-gray-600">
            Developed by <a href="https://codelity.tech">Codelity</a>
          </p>
        </div>
        <div className="px-4">
          <Link to="/contact" className="block mt-2">
            Contact
          </Link>
          <Link to="/legal" className="block mt-2">
            Terms and Conditions
          </Link>
        </div>
      </div>
    </footer>
  );
};

const Layout = ({children}: PropsWithChildren<unknown>): JSX.Element => {
  return (
    <div className="bg-gray-50">
      <Header />
      <main className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-10">{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
