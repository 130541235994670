import {underscore} from '@travelfreedom/shared';
import {ISO_CODES_TO_NAMES} from './knowledge.data';

export namespace Knowledge {
  const isoCodesToNames = new Map<string, string>();
  const namesToIsoCodes = new Map<string, string>();

  Object.entries(ISO_CODES_TO_NAMES).forEach(([code, name]) => {
    isoCodesToNames.set(code, name);
    namesToIsoCodes.set(underscore(name), code);
  });

  export function isoCodeToName(isoCode: string): string {
    return isoCodesToNames.get(isoCode.toUpperCase());
  }

  export function isoCodeToPath(isoCode: string): string {
    return underscore(isoCodesToNames.get(isoCode.toUpperCase()));
  }
}
