import {graphql, Link, useStaticQuery} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';
import React, {useState} from 'react';

const HamburgerIcon = (): JSX.Element => (
  <svg
    className="h-6 w-6"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 6h16M4 12h16M4 18h16"
    />
  </svg>
);

/** Heroicon name: chevron-down Item active: "text-gray-600", Item inactive: "text-gray-400" */
const ChevronDownIcon = (): JSX.Element => (
  <svg
    className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
      clipRule="evenodd"
    />
  </svg>
);

const XIcon = (): JSX.Element => (
  <svg
    className="h-6 w-6"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
    aria-hidden="true"
  >
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
  </svg>
);

type HeaderMobileMenuLinkProps = {
  url: string;
  text: string;
};

type HeaderMenuLinkProps = HeaderMobileMenuLinkProps & {description: string};

const HeaderMobileMenuLink = ({url, text}: HeaderMobileMenuLinkProps): JSX.Element => (
  <Link to={url} className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50">
    <span className="ml-3 text-base font-medium text-gray-900">{text}</span>
  </Link>
);

const HeaderMenuLink = ({url, text, description}: HeaderMenuLinkProps): JSX.Element => (
  <Link to={url} className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50">
    <div className="ml-4">
      <p className="text-base font-medium text-gray-900">{text}</p>
      <p className="mt-1 text-sm text-gray-500">{description}</p>
    </div>
  </Link>
);

const Header = (): JSX.Element => {
  const {file} = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "icon.png"}) {
        childImageSharp {
          gatsbyImageData(width: 28, height: 32, layout: FIXED)
        }
      }
    }
  `);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const focusClasses = 'focus:outline-none focus:ring-2 focus:ring-gray-500';
  const commonButton = `inline-flex items-center justify-center bg-white rounded-md ${focusClasses}`;

  return (
    <div className="relative bg-white border-b-2 border-gray-100 min-w-full">
      <div className="mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center md:justify-start md:space-x-10">
          <div className="flex justify-start md:hidden">
            <Link to="/" className="flex content-center">
              <span className="sr-only">Travelfreedom.io</span>
              <GatsbyImage
                image={file.childImageSharp.gatsbyImageData}
                alt="TravelFreedom.io logo"
                className="h-10 w-auto sm:h-10 m-3"
              />
            </Link>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              onClick={() => {
                setMobileMenuOpen(true);
              }}
              className={`${commonButton} p-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100  focus:ring-inset`}
            >
              <span className="sr-only">Open menu</span>
              <HamburgerIcon />
            </button>
          </div>

          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0" />
          <nav className="hidden md:flex space-x-10">
            <Link to="/" className="flex content-center">
              <span className="sr-only">Travelfreedom.io</span>
              <GatsbyImage
                image={file.childImageSharp.gatsbyImageData}
                alt="TravelFreedom.io logo"
                className="h-10 w-auto sm:h-10 m-3"
              />
            </Link>
            <div className="menu-item relative">
              <button
                type="button"
                className={`${commonButton} group my-4 text-base font-medium  text-gray-500 hover:text-gray-900 focus:ring-offset-2`}
              >
                <span>Documents</span>
                <ChevronDownIcon />
              </button>
              <div className="hidden menu-area absolute z-10 -ml-4 transform w-screen max-w-md lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    <HeaderMenuLink
                      url="/passport"
                      text="Passports"
                      description="Issued to ordinary citizens for ordinary travel, such as for vacation, study and business trips."
                    />

                    <HeaderMenuLink
                      url="/official_passport"
                      text="Official Passports"
                      description="Official passports are issued to officials or employees of governments traveling abroad on official duties."
                    />

                    <HeaderMenuLink
                      url="/diplomatic_passport"
                      text="Diplomatic Passports"
                      description="Diplomatic passport are issued to diplomats and grants them diplomatic privileges."
                    />

                    <HeaderMenuLink
                      url="/national_id"
                      text="National IDs"
                      description="A national identity document is an identity card with a photo, usable as an identity card at least inside the country"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-item relative">
              <button
                type="button"
                className={`${commonButton} group my-4 text-base font-medium text-gray-500 hover:text-gray-900 focus:ring-offset-2`}
              >
                <span>Tools</span>
                <ChevronDownIcon />
              </button>
              <div className="hidden menu-area absolute z-10 left-1/2 transform -translate-x-1/2 w-screen max-w-md">
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                  <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                    <HeaderMenuLink
                      url="/compare"
                      text="Compare"
                      description="Compare passports by their travel freedom."
                    />
                    <HeaderMenuLink
                      url="/ranking"
                      text="Rankings"
                      description="See how your passport ranks among others."
                    />
                    <HeaderMenuLink
                      url="/destination"
                      text="Destinations"
                      description="See visa policies of destinations."
                    />
                  </div>
                </div>
              </div>
            </div>
            <Link
              to="/about"
              className={`inline-flex my-4 text-base font-medium text-gray-500 hover:text-gray-900 ${focusClasses} focus:ring-offset-2`}
            >
              About
            </Link>
          </nav>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0" />
        </div>
      </div>

      <div
        className={`${
          !mobileMenuOpen && 'hidden'
        } absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50`}
      >
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <GatsbyImage
                  image={file.childImageSharp.gatsbyImageData}
                  alt="TravelFreedom.io logo"
                  className="h-8 w-auto"
                />
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  onClick={() => {
                    setMobileMenuOpen(false);
                  }}
                  className={`${commonButton} p-2 text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:ring-inset`}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon />
                </button>
              </div>
            </div>
            <div className="mt-6">
              <nav className="grid gap-y-6">
                <HeaderMobileMenuLink url="/passport" text="Passports" />
                <HeaderMobileMenuLink url="/official_passport" text="Official Passports" />
                <HeaderMobileMenuLink url="/diplomatic_passport" text="Diplomatic Passports" />
                <HeaderMobileMenuLink url="/national_id" text="National IDs" />
                <hr />
                <HeaderMobileMenuLink url="/compare" text="Compare" />
                <HeaderMobileMenuLink url="/ranking" text="Ranking" />
                <HeaderMobileMenuLink url="/destination" text="Destinations" />
                <hr />
                <HeaderMobileMenuLink url="/about" text="About" />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
