import React, {useState} from 'react';
import {ComposableMap, Geographies, Geography, ZoomableGroup} from 'react-simple-maps';
import ReactTooltip from 'react-tooltip';

import {VisaLists} from '../library/common';
import {CompareMapObject, SingleRanking} from '../library/evaluation';

export const MAP_COLORS: Record<VisaLists.FreedomCode, string> = {
  SELF: '#4481CC', // blue
  VISAFREE: '#4B9B4C', // green
  ETA: '#CCC144', // yellow
  VISAPRIOR: '#CC4444', // red
  BANNED: '#383838', // black
};

interface WorldMapData {
  [iso2Code: string]: {
    fillColor: string;
    tooltipText?: string;
  };
}

export function buildMapDataFromVisaLists(visaLists: VisaLists.Data): WorldMapData {
  const data: WorldMapData = {};
  data[visaLists.code] = {
    fillColor: MAP_COLORS.SELF,
  };
  visaLists.visafreeList.forEach((code) => {
    data[code] = {
      fillColor: MAP_COLORS.VISAFREE,
      tooltipText: visaLists.conditions[code],
    };
  });
  visaLists.etaList.forEach((code) => {
    data[code] = {
      fillColor: MAP_COLORS.ETA,
      tooltipText: visaLists.conditions[code],
    };
  });
  visaLists.visapriorList.forEach((code) => {
    data[code] = {
      fillColor: MAP_COLORS.VISAPRIOR,
      tooltipText: visaLists.conditions[code],
    };
  });
  visaLists.bannedList.forEach((code) => {
    data[code] = {
      fillColor: MAP_COLORS.BANNED,
      tooltipText: visaLists.conditions[code],
    };
  });
  return data;
}

export function buildMapDataFromRankingGradient(
  visaLists: VisaLists.Data[],
  singleRanking?: SingleRanking
): WorldMapData {
  const data: WorldMapData = {};
  const fillColors = {};
  Array.from(Array(200).keys()).forEach((i) => {
    const rb = (i * 2.5).toFixed();
    fillColors[i] = `rgb(${rb}, ${140 + i}, ${rb})`;
  });
  visaLists.forEach((visaList) => {
    if (singleRanking) {
      data[visaList.code] = {
        fillColor: fillColors[singleRanking[visaList.globalCode]],
        tooltipText: `Rank: ${singleRanking[visaList.globalCode]}`,
      };
    } else {
      data[visaList.code] = {
        fillColor: 'rgb(0,140,0)',
      };
    }
  });
  return data;
}

export function buildMapDataFromCompareMap(compareMap: CompareMapObject): WorldMapData {
  const data: WorldMapData = {};
  Object.entries(compareMap).forEach(([code, tfCode]) => {
    data[code] = {fillColor: MAP_COLORS[tfCode]};
  });
  return data;
}

type RequiredProps = {
  worldMapData: WorldMapData;
};

type DefaultProps = {
  showLegend?: boolean;
  onTerritoryClick?: (iso2Code: string) => void;
};

const defaultProps: DefaultProps = {
  showLegend: false,
  onTerritoryClick: () => undefined,
};

export type WorldMapProps = RequiredProps & DefaultProps;

export const WorldMap = ({
  worldMapData,
  showLegend,
  onTerritoryClick,
}: WorldMapProps): JSX.Element => {
  const [tooltipContent, setTooltipContent] = useState('');

  return (
    <div className="border rounded">
      <ReactTooltip>{tooltipContent}</ReactTooltip>
      <ComposableMap
        data-tip=""
        projection="geoMercator"
        projectionConfig={{
          rotate: [-10, 0, 0],
          scale: 146,
          center: [0, 42],
        }}
        height={600}
        width={940}
      >
        <ZoomableGroup zoom={1}>
          <Geographies geography="/assets/json/world-50m-simplified.json">
            {({geographies}) =>
              geographies.map((geo) => {
                const {ISO_A2, NAME} = geo.properties;
                const d = worldMapData[ISO_A2];
                const fillColor = (d && d.fillColor) || '#888888';
                const tooltipText =
                  (d && d.tooltipText && `${NAME} — ${d.tooltipText}`) || `${NAME}`;
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    onClick={() => {
                      onTerritoryClick(ISO_A2);
                    }}
                    onMouseEnter={() => {
                      setTooltipContent(tooltipText);
                    }}
                    onMouseLeave={() => {
                      setTooltipContent('');
                    }}
                    fill="#F5F4F6"
                    style={{
                      default: {
                        fill: fillColor,
                        outline: 'none',
                      },
                      hover: {
                        fill: '#888888',
                        outline: 'none',
                      },
                      pressed: {
                        fill: '#888888',
                        outline: 'none',
                      },
                    }}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      {showLegend && (
        <div className="p-2">
          Legend :
          <div>
            <span style={{color: MAP_COLORS.SELF}}>&#9632;</span> - your own country.
          </div>
          <div>
            <span style={{color: MAP_COLORS.VISAFREE}}>&#9632;</span> - countries/territories which
            you can visit without a visa, or you can obtain a visa on arrival.
          </div>
          <div>
            <span style={{color: MAP_COLORS.ETA}}>&#9632;</span> - countries/territories which you
            can visit with an online registration.
          </div>
          <div>
            <span style={{color: MAP_COLORS.VISAPRIOR}}>&#9632;</span> - countries/territories which
            require you to get a visa prior to arrival.
          </div>
          <div>
            <span style={{color: MAP_COLORS.BANNED}}>&#9632;</span> - countries/territories which
            you cannot visit.
          </div>
        </div>
      )}
    </div>
  );
};

WorldMap.defaultProps = defaultProps;
