// eslint-disable-next-line import/no-unresolved
import {useLocation} from '@reach/router';
import React from 'react';

const ReportErrorButton = (): JSX.Element => {
  const errorReportingUrl =
    'https://docs.google.com/forms/d/14swwWZl-YOV4CKld0RY3XZhCU6J2R1HwAOO1rsvcuJU/viewform';

  // This doesn't work during server-side rendering, but we're ok with it.
  const location = useLocation();

  return (
    <a
      className="bg-red-600 hover:bg-red-700 text-white rounded font-bold px-4 py-2 m-2"
      target="_blank"
      rel="noreferrer noopener" // security risk if not specified with target="_blank"
      href={`${errorReportingUrl}?entry.75276214=${location.href}&amp;entry.1323825336`}
    >
      Report an error
    </a>
  );
};

export default ReportErrorButton;
